<template>
  <div class="chat">
    <div class="chat-head">
      <div class="chat-head-text">
        <div class="chat-head-text-list">
          <p>
            <span>{{ $t('INSPECTION_CHAT_HEADER_REQUEST_NO_LABEL') }}</span>
            <span class="fontweight-n">{{requestItem.manageNo}}</span>
          </p>
        </div>
        <div class="chat-head-text-list">
          <p>
            <span>{{ $t('INSPECTION_CHAT_HEADER_MAKER_LABEL') }}</span>
            <span class="fontweight-n">{{ requestItem.makerName }}</span>
          </p>
        </div>
        <div class="chat-head-text-list">
          <p>
            <span>{{ $t('INSPECTION_CHAT_HEADER_BIKE_TYPE_LABEL') }}</span>
            <span class="fontweight-n">{{requestItem.bikeType}}</span>
          </p>
        </div>
      </div>
      <div class="chat-head-btnWrap">
        <a
          class="chat-head-btn blue"
          @click="$router.go(-1)"
        >{{$t("MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL")}}</a>
      </div>
    </div>
    <div class="chat-body">
      <div class="chat-body-wrap" id="chat-area">
        <div v-for="(item, i) in chatMessageData" :key="i">
          <div v-if="item.update_category_id === '1' && item.member_no !== null" class="chat-body-detail mb-5">
            <p class="chat-body-detail-a">回答：{{item.member_nickname}}</p>
            <p class="chat-body-detail-text seller" style="white-space: pre-line;">{{item.message}}</p>
            <div v-if="item.message_flag === false" class="chat-body-detail-btnWrap">
              <div class="chat-tooltip">
                <v-tooltip bottom
                  :color="'primary'">
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      dark
                      v-bind="attrs"
                      v-on="on"
                      src="@/assets/img/common/ic_report.svg"
                      @click="selectMessageNo = item.exhibition_message_no; selectBadMemberNo = item.member_no; report(item.report_flag)"
                    >
                  </template>
                  <span v-if="item.report_flag === false" class="chat-tooltip-text">通報する</span>
                  <span v-else class="chat-tooltip-text">通報済み</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div v-else-if="item.update_category_id === '1' && item.create_admin_no !== null" class="chat-body-detail">
            <p class="chat-body-detail-a">回答：{{ $t('INSPECTION_CHAT_ADMIN_NAME_LABEL') }}</p>
            <p class="chat-body-detail-text seller" style="white-space: pre-line;">{{item.message}}</p>
          </div>
          <div v-else class="chat-body-detail question_detail">
            <p v-if="item.admin_no" class="chat-body-detail-q">
              質問{{item.cnt}}：{{ $t('INSPECTION_CHAT_ADMIN_NAME_LABEL') }}
            </p>
            <p v-else-if="item.member_no" class="chat-body-detail-q">
              質問{{item.cnt}}：{{item.member_nickname}}
            </p>
            <p class="chat-body-detail-text user" style="white-space: pre-line;">{{item.message}}</p>
            <div v-if="item.message_flag === false" class="chat-body-detail-btnWrap">
              <div class="chat-tooltip">
                <v-tooltip bottom
                  :color="'primary'">
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      dark
                      v-bind="attrs"
                      v-on="on"
                      src="@/assets/img/common/ic_report.svg"
                      @click="selectMessageNo = item.exhibition_message_no; selectBadMemberNo = item.member_no; report(item.report_flag)"
                    >
                  </template>
                  <span v-if="item.report_flag === false" class="chat-tooltip-text">通報する</span>
                  <span v-else class="chat-tooltip-text">通報済み</span>
                </v-tooltip>
              </div>
              <a v-if="exhibitionMemberFlag" class="chat-body-detail-btn blue" @click="selectMessageNo = item.exhibition_message_no; answerFlg = false;">回答する</a>
            </div>
          </div>
        </div>
      </div>
      <a @click="chatStore.getChatMessage()" class="btn-reload"><img src="@/assets/img/common/ic_reload.svg" alt=""></a>
    </div>

    <div class="chat-foot">
      <form class="chat-form" action="">
        <div v-if="errors.length > 0">
          <p class="err-txt">{{ errors }}</p>
        </div>
        <div class="chat-form-textarea">
          <textarea v-if="exhibitionMemberFlag || agencyFlg" type="text" id="chatInput" placeholder="コメントを入力してください" v-model="inputMessage" :disabled="answerFlg"></textarea>
          <textarea v-else type="text" name="" id="chatInput" placeholder="コメントを入力してください" v-model="inputMessage" :disabled="!isLoggedin"></textarea>
        </div>
        <div v-if="exhibitionMemberFlag || agencyFlg" class="chat-form-btnWrap">
          <button class="chat-form-btn blue" type="submit" @click="chatStore.regist" :disabled="answerFlg">{{$t("INSPECTION_CHAT_POST_BUTTON_LABEL")}}</button>
          <button class="chat-form-btn lt_gray" type="reset" @click="reset" :disabled="answerFlg">{{$t("INSPECTION_CHAT_CLEAR_BUTTON_LABEL")}}</button>
        </div>
        <div v-else class="chat-form-btnWrap">
          <button class="chat-form-btn blue" type="submit" @click="chatStore.regist" :disabled="!isLoggedin">{{$t("INSPECTION_CHAT_POST_BUTTON_LABEL")}}</button>
          <button class="chat-form-btn lt_gray" type="reset" @click="reset" :disabled="!isLoggedin">{{$t("INSPECTION_CHAT_CLEAR_BUTTON_LABEL")}}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useChat from '@/stores/useChat'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const chatStore = useChat()
    return {
      chatStore
    }
  },
  components : {
  },
  props : {
    isPreview : {
      type    : Boolean,
      default : false
    },
    requestResultData : {
      type    : Object,
      default : () => {}
    },
  },
  data() {
    return {
      cnt : 0
    }
  },
  computed : {
    constants() {
      return this.chatStore.constants
    },
    requestItem() {
      return this.chatStore.requestItem
    },
    chatMessageData() {
      return this.chatStore.chatMessageData
    },
    exhibitionMemberFlag() {
      return this.chatStore.exhibitionMemberFlag
    },
    agencyFlg() {
      return this.chatStore.agencyFlg
    },
    answerFlg : {
      get() {
        return this.chatStore.answerFlg
      },
      set(val) {
        this.chatStore.answerFlg = val
      }
    },
    inputMessage : {
      get() {
        return this.chatStore.inputMessage
      },
      set(val) {
        this.chatStore.inputMessage = val
      }
    },
    selectMessageNo : {
      get() {
        return this.chatStore.selectMessageNo
      },
      set(val) {
        this.chatStore.selectMessageNo = val
      }
    },
    selectBadMemberNo : {
      get() {
        return this.chatStore.selectBadMemberNo
      },
      set(val) {
        this.chatStore.selectBadMemberNo = val
      }
    },
    isLoggedin() {
      return !!this.chatStore.loginMemberNo
    },
    errors() {
      return this.chatStore.errors
    },
  },
  methods : {
    reset() {
      this.inputMessage = null
    },
    report(report_flag) {
      if (report_flag === false) {
        this.chatStore.openReportComfirmDialog()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
form .form-wrap {
  align-items: start !important;
}
.selection-txt {
  margin-left: 10px;
}
.btn-reload{
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-top: 5px;
  img {
      width: 35px;
      height: 100%;
      vertical-align: middle;
    }
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss">
</style>
