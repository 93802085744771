import { render, staticRenderFns } from "./ChatItem.vue?vue&type=template&id=6ba19adc&scoped=true&"
import script from "./ChatItem.vue?vue&type=script&lang=js&"
export * from "./ChatItem.vue?vue&type=script&lang=js&"
import style0 from "./ChatItem.vue?vue&type=style&index=0&id=6ba19adc&prod&lang=scss&scoped=true&"
import style1 from "@/assets/scss/style.scss?vue&type=style&index=1&id=6ba19adc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba19adc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})
