<template>
  <main id="main">
    <section id="exhibit" class="exhibit">
      <InquiryChat/>
    </section>
  </main>
</template>

<script>
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import InquiryChat from '../../components/Chat/InquiryChatForm'
import useChat from '../../stores/useChat'
export default defineComponent({
  setup() {
    const chatStores = useChat()
    const auctionRequestStore = inspectionAuctionRequest()
    const loadingStore = useLoading()
    return {
      chatStores,
      auctionRequestStore,
      loadingStore
    }
  },
  components : {
    InquiryChat
  },
  mounted() {
    this.loadingStore.setLoading(true)
    const selectExhibitionItemNo = this.$route.params ? this.$route.params.id : null
    this.chatStores.reset(selectExhibitionItemNo, 'inquiry')
      .then(() => {
        this.$nextTick(() => {
          document.getElementById('chatInput')?.focus()
        })
        this.loadingStore.setLoading(false)
      })
      .catch(error => {
        this.loadingStore.setLoading(false)
        this.$router.go(-1)
      })
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
